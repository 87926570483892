require('./jquery.spincrement.min');

$(window).scroll(function () {
    let cPos = $('#counters').offset().top;
    let topWindow = $(window).scrollTop();

    if (cPos < topWindow + 500) {
        $('.number').each(function () {
            let num = $(this).data('num');

            $(this).spincrement({
                from: 0,
                to: num,
                duration: 2000
            })
        });
    }
});
